<template>
  <div class="chart-tab">
    <div class="chart-filters">
      <b-select
        v-if="isSingleIOSelected && liftTypeOptions.length"
        :options="liftTypeOptions"
        :selected="liftTypeValue"
        :class-name="'greyBackground'"
        @change="onChangeLiftType"
      />
      <span v-if="isSingleIOSelected && liftTypeOptions.length" class="pan-by">by</span>
      <b-select
        v-if="isSingleIOSelected && liftTypeOptions.length"
        :options="groupByOptions"
        :selected="groupByValue"
        :class-name="'greyBackground'"
        @change="onChangeGroupBy"
      />
      <div v-if="!loading && columns.length" class="total-respondents">
        <div class="fltr-respondents r-exposed">
          Exposed: <span class="r-val">{{ formatValWithUnit(totalExposedRespondents) }}</span>
        </div>
        <div class="fltr-respondents r-control">
          Control: <span class="r-val">{{ formatValWithUnit(controlRespondents) }}</span>
        </div>
      </div>
      <div
        v-if="!loading"
        class="select-campaign-btn"
        :class="[
          showFilters && isSingleIOSelected && liftTypeOptions.length ? 'shrinkSelectBtn' : '',
        ]"
      >
        <k-button :size="3" label="SELECT CAMPAIGN" @click="handleSelectCampaign" />
      </div>
    </div>
    <div v-if="!loading && columns.length" class="lift-ques">
      <span class="lift-ques-label">{{ liftTypeValue }}:</span>
      {{ getLiftTypeQuestionText() }}
    </div>
    <div v-if="isSingleIOSelected">
      <bar-chart
        v-if="!loading && columns.length && groupByValue != 'overall'"
        class="bar-chart container-chart"
        :columns="columns"
        :legends="legends"
        :tooltip="tooltip"
      />
      <div
        v-if="!loading && columns.length && groupByValue === 'overall'"
        class="bl-stats-container"
      >
        <div class="lift-bubble">
          <div class="lb-title">Lift</div>
          <div
            :class="['lb-value', overall.liftPercentage >= 0 ? 'positiveColor' : 'negativeColor']"
          >
            {{ formatPercentage(overall.liftPercentage) }}
          </div>
        </div>
        <div class="bl-stat-wrap">
          <div class="bl-txt">
            {{ liftTypeValue }} has
            <span v-if="overall && overall.liftPercentage && overall.liftPercentage > 0">
              a
              <span class="positiveColor bold"
                >Positive lift of {{ formatPercentage(overall.liftPercentage) }}
              </span>
            </span>
            <span v-if="overall && overall.liftPercentage && overall.liftPercentage < 0">
              a
              <span class="negativeColor bold"
                >Negative lift of {{ formatPercentage(-1 * overall.liftPercentage) }}
              </span>
            </span>
            <span v-if="overall && overall.liftPercentage === 0">
              <span class="positiveColor bold">no lift</span>
            </span>
            <span v-if="overall.significant === 1">
              and is
              <span class="positiveColor bold">statistically significant</span> at 90% Confidence
            </span>
            with
            <span class="highlight-text bold">
              {{ formatVal(overall.exposedRespondents, '0,0') }} exposed
            </span>
            and
            <span class="highlight-text bold">
              {{ formatVal(controlRespondents, '0,0') }} control
            </span>
            completes.
          </div>
          <br />
          <div class="bl-sig">
            <font-awesome-icon
              :icon="['fas', overall.significant === 1 ? 'check' : 'times']"
              :class="[
                'bl-font-awesome sig-check',
                overall.significant === 1 ? 'positiveColor' : 'negativeColor',
              ]"
            />
            Statistically Significant
          </div>
        </div>
      </div>
      <horizontal-bar-chart
        v-if="!loading && columns.length && groupByValue === 'overall'"
        class="bar-chart container-chart"
        :columns="columns"
        :x-axis-label-format="`0.[00]%`"
      />
      <div v-else-if="!loading && !columns.length" class="txt-center">
        No data is available to show
      </div>
      <b-loading-spinner v-else-if="loading" class="txt-center" />
    </div>
    <div v-if="!isSingleIOSelected && !loading">
      <div class="txt-center">
        Choose only one campaign to see Brand Lift data
      </div>
    </div>
  </div>
</template>

<script>
import { pick } from 'underscore';
import { get } from 'vuex-pathify';
import { isBlank } from 'adready-api/helpers/common';

import advertiserReportsAPI from '~/api/advertiser-reports';
import { buildQueryString } from '~/helpers/global/url-helpers';
import formatNumber from '~/util/numeral';
import { formatNumberAsLargeNumber } from '~/util/utility-functions';
import * as CONSTANT from '~/constant';

import graphDataMixin from '~/components/mixins/graph-data-mixin';

export default {
  name: 'BrandLift',
  components: {
    BarChart: () => import(/* webpackChunkName: "bar-chart" */ '~/components/charts/bar-chart.vue'),
    HorizontalBarChart: () =>
      import(
        /* webpackChunkName: "horizontal-bar-chart" */ '~/components/charts/horizontal-bar-chart.vue'
      ),
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
    BSelect: () => import(/* webpackChunkName: "b-select" */ '~/components/elements/b-select.vue'),
  },

  mixins: [graphDataMixin],

  data() {
    return {
      CONSTANT,
      liftTypeOptions: [],
      liftTypeMap: [],
      liftTypeValue: 'None',
      groupByOptions: [],
      groupByValue: '',
      totalExposedRespondents: 0,
      controlRespondents: 0,
      controlPercentage: 0,
      columns: [],
      legends: [],
      tooltip: [],
      overall: {},
    };
  },

  computed: {
    advertiser: get('common/advertiser'),
    account: get('common/account'),
    selectedIOs: get('dashboard/GET_SELECTED_IOS'),
    ioOptions: get('dashboard/filters@ioOptions'),
    showFilters: get('common/showFilters'),

    isSingleIOSelected() {
      return this.selectedIOs.length === 1;
    },
  },
  watch: {
    async selectedIOs() {
      try {
        await this.fetchLiftTypes(this.payload);
        await this.loadGraphData(this.payload);
      } catch (err) {
        console.error('error mounting brand-lift-chart', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
    },
  },
  async mounted() {
    try {
      await this.fetchLiftTypes(this.payload);
      await this.loadGraphData(this.payload);
    } catch (err) {
      console.error('error mounting brand-lift chart', err);
      if (window.$sentry) {
        if (err._reported !== true) {
          window.$sentry.captureException(err);
          err._reported = true;
        }
      }
    }
  },
  methods: {
    formatValWithUnit(value) {
      return formatNumber(value, 'VISITS');
    },
    formatVal(value, format) {
      return formatNumberAsLargeNumber(value, format);
    },
    formatPercentage(value) {
      return this.formatVal(value, '0%');
    },
    getLiftTypeQuestionText() {
      if (isBlank(this.liftTypeMap)) {
        return '';
      }
      const entry = this.liftTypeMap.find((e) => e.key === this.liftTypeValue);
      return entry ? entry.value : '';
    },
    async onChangeLiftType(val) {
      const payload = { ...this.payload };
      this.liftTypeValue = val;

      try {
        await this.loadGraphData(payload);
      } catch (err) {
        console.error('Error in updating brand-lift chart ->', err.message, err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
    },
    async onChangeGroupBy(val) {
      const payload = { ...this.payload };
      this.groupByValue = val;

      try {
        await this.loadGraphData(payload);
      } catch (err) {
        console.error('Error in updating brand-lift chart ->', err.message, err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
    },
    buildTooltipDataRow(rowCss, title, titleCss, value, valueCss, formatter) {
      const formattedValue = formatter ? this.formatVal(value, formatter) : value;
      return {
        rowCss,
        columns: [
          {
            value: title,
            css: titleCss,
          },
          {
            value: formattedValue,
            css: valueCss,
          },
        ],
      };
    },
    handleSelectCampaign() {
      this.$store.dispatch('common/setSelectOneCampaignType', CONSTANT.WINDOW_TYPE_BRAND_LIFT);
      if (!this.showFilters) {
        this.$store.dispatch('common/setShowFilters', true);
        setTimeout(() => {
          if (document.getElementById('ioOptionDropdown')) {
            document.getElementById('ioOptionDropdown').click();
          }
        }, 400);
      }
    },
    buildTooltipEmptyRow() {
      return {
        rowCss: 'ctip-div',
      };
    },
    keepRequiredPayloadParameters(payload) {
      const clone = pick(payload, ['client', 'advertiser']);
      clone.io = this.selectedIOs || [];
      if (!clone.io.length) {
        clone.io = '-';
      }
      const selectCampaigns = this.ioOptions.filter((o) => o.checked && o.campaignId);
      const campaignIds = selectCampaigns.map((o) => o.campaignId);
      const campaignId = campaignIds && campaignIds.length > 0 ? campaignIds[0] : 0;

      clone.intCampaignId = campaignId;
      return clone;
    },
    async processResultsOverall(res) {
      this.totalExposedRespondents = res.totalExposedRespondents;
      this.controlRespondents = res.controlRespondents;
      this.controlPercentage = res.controlPercentage;

      this.overall = {};
      const data = [];
      let row = [];

      if (res.breakup.length) {
        const first = res.breakup[0];
        row.push('x');
        row.push('Overall');
        data.push(row);

        row = [];
        row.push('Exposed Conversion Rate');
        row.push(first.exposedPercentage);
        data.push(row);
        this.overall = first;
      }

      row = [];
      row.push('x');
      row.push('Overall');
      data.push(row);

      row = [];
      row.push('Control Conversion Rate');
      row.push(res.controlPercentage);
      data.push(row);

      this.columns = data;
    },
    async processResults(res) {
      this.totalExposedRespondents = res.totalExposedRespondents;
      this.controlRespondents = res.controlRespondents;
      this.controlPercentage = res.controlPercentage;
      this.legends = [];
      const data = [];
      this.tooltip = [];
      res.breakup.forEach((row) => {
        if (row.liftPercentage === 0) {
          return;
        }
        this.legends.push(row.name);
        data.push(row.liftPercentage);
        const tooltipRows = [];
        tooltipRows.push(
          this.buildTooltipDataRow(
            'ctip-row-wrap exp',
            'Exposed count',
            'ctip-row-name',
            row.exposedRespondents,
            'ctip-row-val',
            '0,0'
          )
        );
        tooltipRows.push(
          this.buildTooltipDataRow(
            'ctip-row-wrap exp',
            'Exposed %',
            'ctip-row-name',
            row.exposedPercentage,
            'ctip-row-val',
            '0%'
          )
        );
        tooltipRows.push(this.buildTooltipEmptyRow());

        tooltipRows.push(
          this.buildTooltipDataRow(
            'ctip-row-wrap con',
            'Control count',
            'ctip-row-name',
            res.controlRespondents,
            'ctip-row-val',
            '0,0'
          )
        );
        tooltipRows.push(
          this.buildTooltipDataRow(
            'ctip-row-wrap con',
            'Control %',
            'ctip-row-name',
            res.controlPercentage,
            'ctip-row-val',
            '0%'
          )
        );
        tooltipRows.push(this.buildTooltipEmptyRow());

        tooltipRows.push(
          this.buildTooltipDataRow(
            'ctip-head-wrap',
            'Lift %',
            'ctip-name',
            row.liftPercentage,
            'ctip-dif',
            '0.[0]%'
          )
        );
        const isSignificant = row.significant === 1;
        tooltipRows.push(
          this.buildTooltipDataRow(
            'ctip-head-wrap',
            'Statistical Significance',
            'ctip-name',
            isSignificant ? '✓' : '𐄂',
            isSignificant ? 'ctip-positive' : 'ctip-negative'
          )
        );
        this.tooltip.push(tooltipRows);
      });

      this.columns = data;
    },
    async loadGraphData(payload) {
      this.columns = [];
      if (this.liftTypeOptions.length === 0) {
        this.loading = false;
        return;
      }

      this.loading = true;
      payload = this.keepRequiredPayloadParameters({ ...payload });
      payload.liftType = this.liftTypeValue;
      payload.groupBy = this.groupByValue;

      // call brand lift api and get res
      try {
        const res = await advertiserReportsAPI.brandLiftDistribution(
          this.advertiser.id,
          buildQueryString(payload)
        );
        if (res && res.breakup && res.breakup.length) {
          if (this.groupByValue === 'overall') {
            this.processResultsOverall(res);
          } else {
            this.processResults(res);
          }
        }
      } catch (err) {
        console.error('error in fetching brand-lift data ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
        throw err;
      } finally {
        this.loading = false;
      }
    },
    async fetchLiftTypes(payload) {
      if (this.isSingleIOSelected) {
        const io = this.ioOptions.find((o) => o.value === this.selectedIOs[0]);
        if (!io || !io.brandLiftEnabled) {
          return;
        }
        // call api to fetch lift types
        this.loading = true;
        payload = this.keepRequiredPayloadParameters({ ...payload });
        try {
          const res = await advertiserReportsAPI.brandLiftTypes(
            this.advertiser.id,
            buildQueryString(payload)
          );
          this.liftTypeMap = res;
          if (this.liftTypeMap.length > 0) {
            const groupBy = await advertiserReportsAPI.brandLiftGroupBy(
              this.advertiser.id,
              buildQueryString(payload)
            );
            const opt = groupBy.map((liftGroupBy) => liftGroupBy.key);
            this.groupByOptions = [...opt];
            this.groupByValue =
              this.groupByOptions.length && this.groupByOptions.length > 0
                ? this.groupByOptions[0]
                : 'None';
          }
        } catch (err) {
          console.error('error in fetching brand-lift types ->', err);
          if (window.$sentry) {
            if (err._reported !== true) {
              window.$sentry.captureException(err);
              err._reported = true;
            }
          }
          throw err;
        } finally {
          this.loading = false;
        }
      }

      const opts = this.liftTypeMap.map((liftType) => liftType.key);
      this.liftTypeOptions = [...opts];
      this.liftTypeValue =
        this.liftTypeOptions.length && this.liftTypeOptions.length > 0
          ? this.liftTypeOptions[0]
          : 'None';
    },
  },
};
</script>

<style lang="scss" scoped>
.select-campaign-btn.shrinkSelectBtn {
  width: 100px;
  -webkit-transition: width 0.3s ease;
  -moz-transition: width 0.3s ease;
  -ms-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease;
}
.select-campaign-btn {
  position: absolute;
  right: 5px;
  top: 3px;
}
::v-deep .select-campaign-btn .k-btn:focus {
  background-color: var(--primarycolor);
}
@media screen {
  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .opt-btn:hover {
    color: var(--primarycolor);
  }
}

::v-deep .dropdown {
  width: 220px !important;
}

::v-deep .c3-event-rect {
  cursor: auto !important;
}
.pan-by {
  position: relative;
  display: inline-block;
  padding: 10px 10px;
  font-size: 14px;
  color: #cad1d6;
}
.loading-spinner {
  width: 100px;
  height: 100px;
}
.container-chart {
  position: relative;
  z-index: 2;
  margin-top: 40px;
  margin-bottom: -30px;
  border: 0px #ffffff none;
}
.chart-tab {
  .txt-center {
    text-align: center;
    margin-top: 100px;
  }
}
.chart-filters {
  display: flex;
  position: relative;
  .chart-widget {
    position: absolute;
    right: 55px;
  }
  .flp-opt-panel {
    top: 40px;
    right: 30px;
  }
}
.lift-ques {
  display: inline-block;
  margin-top: 10px;
  margin-bottom: -10px;
  margin-left: 10px;
  font-size: 14px;
}
.lift-ques-label {
  color: #939ba0;
}
.bl-stats-container {
  padding-left: 10px;
  margin-top: 30px;
  margin-bottom: -15px;
  vertical-align: middle;
}
.lift-bubble {
  display: inline-block;
  padding: 7px 11px 12px;
  margin-top: 4px;
  text-align: center;
  vertical-align: middle;
  background-color: #30323a;
  border-radius: 7px;
}
.lb-title {
  font-size: 13px;
  font-weight: 600;
  line-height: 1.2em;
  color: #abb2c5;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.lb-value {
  font-size: 24px;
}
.bl-stat-wrap {
  display: inline-block;
  max-width: 79%;
  padding-left: 20px;
  margin-left: 20px;
  font-size: 14px;
  vertical-align: middle;
  border-left: 1px solid #2f3337;
}
.bl-txt {
  display: inline-block;
  text-align: left;
}
.sig-check {
  margin-right: 5px;
}
.bl-sig {
  display: inline-block;
  padding: 1px 8px;
  margin-top: 5px;
  background-color: #30323a;
  border-radius: 4px;
  color: #fff;
}
.bl-font-awesome {
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  vertical-align: -0.125em;
}
.total-respondents {
  display: inline-block;
  width: 90px;
  margin-left: 20px;
  vertical-align: middle;
}
.fltr-respondents {
  font-size: 13px;
  text-transform: uppercase;
}
.fltr-respondents.r-exposed {
  margin-bottom: 3px;
  font-weight: 600;
  color: var(--primarycolor);
}
.fltr-respondents.r-control {
  margin-bottom: 20px;
  color: #939ba0;
}
.r-val {
  display: inline-block;
  float: right;
  font-size: 15px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

::v-deep .wrap-longtxt {
  width: auto;
  max-width: 275px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cur-amt {
  margin: 10px;
  font-size: 48px;
  font-weight: 300;
}

.highlight-text {
  color: var(--primarycolor);
}

.positiveColor {
  color: var(--primarygreen);
}

.negativeColor {
  color: var(--primaryred);
}

.bold {
  font-weight: 600;
}

// gap between bars
::v-deep g.c3-chart-bar.c3-target.c3-target-Control-Conversion-Rate {
  transform: translateY(4px);
}

// bar glow
// ::v-deep path.c3-shape.c3-shape-0.c3-bar.c3-bar-0 {
// filter: drop-shadow(4px 0px 14px rgba(0, 171, 183, 0.2));
// }

::v-deep .chart-filters .dropdown .dropdown-menu li {
  font-size: 14px;
}
</style>
